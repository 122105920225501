* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  

  
  .lang_switch {
    position: relative;
    display: flex;
    margin: auto;
    padding: 10px;
    font-size: 14px;
    color:#7F8CA0;
  }
  .lang_switch__text {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .lang_switch__text:first-of-type {
    margin-right: 5px;
  }
  .lang_switch__blobs {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: url("#goo");
  }
  .lang_switch__blobs::before, .lang_switch__blobs::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #DCDDE0;
    /* box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(0, 0, 0, 0),
    0em 0em 1em rgba(0, 0, 0, 0); */
    transition: transform 0.2s cubic-bezier(0.51, 0.06, 0.56, 1.37);
    will-change: transform;
  }
  .lang_switch__blobs::before {
    transform: scale(0.7);
    transition-delay: 0.1s;
    transition-duration: 0.4s;
  }
  
  .lang_switch.changed .lang_switch__blobs::before {
    transform: translateX(45px) scale(0.7);
  }
  .lang_switch.changed .lang_switch__blobs::after {
    transform: translateX(45px);
  }
  
  @media only screen and (min-width: 1025px) {
    .lang_switch__blobs::before {
      transform: scale(1);
      transition-delay: 0s;
      transition-duration: 0.2s;
    }
  
    .lang_switch:hover {
      cursor: pointer;
    }
  
    .lang_switch:not(.changed) .en:hover ~ .lang_switch__blobs::before {
      transform: scale(0.9);
    }
    .lang_switch:not(.changed) .en:hover ~ .lang_switch__blobs::after {
      transform: translateX(45px) scale(0.7);
    }
  
    .lang_switch.changed .lang_switch__blobs::before {
      transform: translateX(45px);
    }
    .lang_switch.changed .pl:hover ~ .lang_switch__blobs::before {
      transform: translateX(45px) scale(0.9);
    }
    .lang_switch.changed .pl:hover ~ .lang_switch__blobs::after {
      transform: scale(0.7);
    }
  }